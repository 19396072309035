<template lang="pug">
include ../../../configs/template.pug
div.row.p-1
  div.col-6
    +date-picker-validation('body.date_start', 'dateEffective', 'dateStartObject', '["required", "minValue", "maxValue"]')(max="2200-01-01" :min="dateTomorrow")
  div.col-3
    +field-validation('body.price', 'firstForm', '["required"]')
  div.col-3
    +field-validation('body.unit', 'secondForm', '["required"]')
  div.col-12.d-flex.justify-center.mt-3
    v-btn(:loading="isLoading" color="success" @click="saveNostrificationMesPrice") {{ $t('save') }}
</template>

<script>
import { mapActions, mapState } from 'vuex'
import { translateAgencyPrice } from '@/mixins/validationRules'
import { dateFormat } from '@/mixins/main'

const initBody = () => ({
  date_start: null,
  price: null,
  unit: null,
  translation_company: null
})

export default {
  props: {
    actionType: { type: String, default: 'edit' },
    row: { type: Object, default: () => ({}) }
  },
  data () {
    return {
      body: initBody(),
      dateTomorrow: null
    }
  },
  computed: {
    ...mapState({
      languagesList: state => state.directory.languages,
      labelName: state => (state.main.lang === 'en') ? 'name_eng' : 'name_ukr',
      isLoading: state => state.backoffice.isLoading
    }),
    documentID () { return this.$route.params.id },
    dateStartObject () { return this.body.date_start ? new Date(this.body.date_start) : null }
  },
  beforeMount () {
    // Get tomorrow's date
    let tomorrow = new Date()
    tomorrow.setDate(new Date().getDate() + 1)
    this.dateTomorrow = dateFormat(tomorrow)

    this.setFieldsAutocomplete()
  },
  validations () {
    return translateAgencyPrice(this)
  },
  methods: {
    ...mapActions(['setNostrificationMesPrice', 'updateNostrificationMesPrice']),

    async saveNostrificationMesPrice () {
      if (this.$v.$invalid) return this.$v.$touch()

      const params = this.actionType === 'edit' ? { id: this.row.id, body: this.body } : this.body
      const response = await this[this.actionType === 'edit' ? 'updateNostrificationMesPrice' : 'setNostrificationMesPrice'](params)
      if ([200, 201].includes(response?.code)) {
        this.$notification.success('notify.successNotify.changedPrice')
        if (this.actionType === 'add') {
          this.body = initBody()
          this.$v.$reset()
        }
      }
    },

    setFieldsAutocomplete () {
      if (this.actionType === 'edit') {
        this.body = Object.keys(this.body).reduce((t, k) => ({ ...t, [k]: this.row[k] }), {})
      } else this.body.translation_company = this.documentID
    }
  }
}
</script>
